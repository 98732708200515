import { z } from 'zod';

const schemaFormMetricsListValidation = z.array(z.any());
const schemaFormValidationSchema = z.object({
  name: z.string().min(3, 'Must be at least 3 characters long'),
  groups: z.array(z.object({
    displayName: z.string().min(3, 'Must be at least 3 characters long'), // Add unique validation
    isRepeatingGroup: z.boolean(),
    calculatedTotals: z.boolean(),
    generalMetrics: schemaFormMetricsListValidation,
    subGroups: z.array(z.object({
      metrics: schemaFormMetricsListValidation,
      displayName: z.string().min(1, 'Required'),
      enableTimePeriodFilter: z.boolean(),
    }).refine((data) => {
      if (data.enableTimePeriodFilter === true && data.metrics.length) {
        return data.metrics.filter((metric) => metric.timePeriod).length === data.metrics.length;
      }
      return true;
    }, { message: 'All metrics must have an associated time period' })
      .refine((data) => data.metrics.length !== 0, { message: 'View must contain at least 1 metric' })),
  })),
});

export default schemaFormValidationSchema;
